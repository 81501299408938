@font-face {
  font-family: 'vera';
  src: url('fonts/vera.ttf')  format('truetype'), /* Safari, Android, iOS */
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #333231;
  flex-direction: column;
  font-family: Poppins;
  height: 100%;
  color: #cfcfcf;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MapContainer{
  height: 100%;
  width: 100%;
  border: 0px solid #cfcfcf;
  background-color: #798889;
  border-radius: 50%;
}

.leaflet-bottom.leaflet-right{
  display: none;
}

.leaflet-control-zoom{
  display: none;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MapBorder{
  width: 300px;
  height: 300px;
  padding: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "vera"
}


.MapOverlay{
  color: #c2403c;
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins,sans-serif;
  background-image: url("img/mapborder4.png");
  background-size:     cover;  
  pointer-events: none;
}

.MapOverlayCircle{
  color: #c2403c;
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  border-radius: 50%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins,sans-serif;
  background-image: url("img/minimap_frame.png");
  background-size:     cover;  
  pointer-events: none;
}

.MapButton{
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 100%;
}

.MapButton1{
  z-index: 999;
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 110%;
  position: absolute;
  right: 29%;
  top: 0%;
  cursor: pointer;
}

.MapButton2{
  z-index: 999;
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 90%;
  position: absolute;
  right: 18%;
  top: 6%;
  cursor: pointer;
}

.MapButton3{
  z-index: 999;
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 90%;
  position: absolute;
  right: 8%;
  top: 14%;
  cursor: pointer;
}

.MapButton4{
  z-index: 999;
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 90%;
  position: absolute;
  right: 1%;
  top: 24%;
  cursor: pointer;
}

.MapButton5{
  z-index: 999;
  background-color: #070606;
  border: 1px solid #656665;
  border-radius: 5px;
  color: #ABA9A6;
  height: 5%;
  width: 5%;
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  right: -3%;
  top: 35%;
  cursor: pointer;
}

.MapButtons{
  position: absolute;
  right: 5px; 
  top: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 999;
  cursor: pointer;
}

.app-header {
  display: flex;
  height: 30px;
  background-color: #191816;
  color: #eeefed;
  font-size: 26px;
  border-bottom: 1px solid #121110;
  flex: 0;
}

.window-controls-group {
  margin-left: auto;
  display: flex;
  --color: #a8a8a8;
  --color-hover: #dedede;
  --bg-color-hover: #272727;
}

.window-control {
  color: var(--color);
  background-color: transparent;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.window-control:hover, .window-control:active {
  color: var(--color-hover);
  background-color: var(--bg-color-hover);
}

.window-control-close {
  --color-hover: #dedede;
  --bg-color-hover: #c21913;
}

.settings{
  display: flex;
  background-color: #232220;
  color: #eeefed;
  user-select: none;
  font-family: Poppins,sans-serif;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid #121110;
}

.settingsContent{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 30px;
  flex-wrap: none;
  overflow: auto;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 0
}

.settingsSection{
  display: flex;
  align-items: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.settingsRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #d6d7d5;
  font-size: 14px;
  width: calc(100%-8px);
  margin: 4px;
}

.app-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsSubtitle{
  color: #d6d7d5;
  font-size: 12px;
}

.settingsHotkey{
  color: #c2403c;
  cursor: pointer;
}

.settingsAbout{
  color: #d6d7d5;
  font-size: 12px;
  text-align: center;
}

.settingsAboutRow{
  margin-top: 3px;
  margin-bottom: 3px;
}

.sliderBox{
  width: 200px;
  margin-left: 20px;
  margin-right: 30px;
}

.Title{
  color: #eeefed;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.Row{
  color: #d6d7d5;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

.Logo{
  align-self: center;
  max-height: 200px;
  height: 200px;
  width: 200px;
}

.Link{
  color: #c2403c !important;
}

.footer {
  background-color: #1b1b1a;
  width: 100%;
  border-top: 1px solid #111;
  color: #d6d7d5;
}

.DownloadButton{
  height: 70px;
  max-height: 70px;
  align-self: center;
  cursor: pointer;
  margin: 5px;
}

.SettingsContainer{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 150px;
  max-width: 100%;
  text-align: center;
  z-index: 20000;
  overflow: auto;
  height: 700px;
  width: 600px;
  display: flex;
  flex: 1 1 auto;
}

.PlayerMarker{
  transition-duration: 1s;
}

.settingsContentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #232220;
  overflow: auto;
}

.rct-icon-parent-open{
  display: none;
}
.rct-icon-leaf-close{
  display: none;
}

.rct-node-icon{
  display: none;
}

.rct-title{
  color: #eeefed;
}

label{
  color: #d6d7d5;
}

.rct-collapse{
  font-size: 30px;
}

.MarkerButton{
  color: #c2403c;
  cursor: pointer;
}

#PlayerIcon{
  position:absolute;
  top: calc(50%-15px);
  left: calc(50%-15px);
  height: 30px;
  width: 30px;
  pointer-events: none;
}

.ol-zoom{
  display: none;
}

.ol-rotate{
  display: none;
}

.Sidebar{
  display: flex;
  width: 401px;
  min-width: 401px;
  flex-direction: column;
  justify-content: space-between;
  color: #d6d7d5;
  background-color: #2e2d2c;
  border-right: 1px solid #222;
  max-height: 100%;
  height: 100%;
  position: relative;
}

.minimizeSidebar{
  position: absolute;
  display: flex;
  right: -14px;
  top: calc(50% - 10px);
  width: 14px;
  height: 30px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 200;
  background-color: #2e2d2c;
}

.SidebarTop{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 0px;
}

.ad_space_nwmm {
  height: 300px;
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0;
}

.ad_bg_nwmm{
  height: 255px;
  opacity: 0.5; 
  position: absolute;
  top: 25px;
  left: 75px;
  z-index: 0;
}

.ad_container_nwmm{
  min-height: 300px;
  min-width: 400px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ncmp__tool{
  padding: 0px !important;
}

#ac-lre-player-ph{
  margin-top: 37px;
}

.ad_message_nwmm{
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: #cfcfcf;
  padding-bottom: 3px;
}

.MainContainer{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  user-select: none;
}

.SidebarRow {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.SidebarRow:hover {
  color: #eeefed;
}

.SidebarRow.active {
  color: #eeefed;
}

.SidebarRowIcon{
  position: absolute;
  right: 5%;
  height: 24px;
  width: 24px;
}

.ad_space_container_nwmm{
  display: flex;
  min-height: 321px;
  flex-direction: column;
  flex: 0;
}

.FullscreenMapContainer{
  flex: 1;
}

.MapBorder2{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "vera";
  overflow: hidden;
}

.MarkerLimitWarning{
  color: #c2403c;
  background-color: #232220;
  outline: 1px solid #121110;
  text-align: center;
  padding: 5px;
  font-size: 80%;
  border-radius: 4px;
  bottom: 5%;
  position: absolute;
  max-width: 75%;
  z-index: 9999;
  opacity: 0.9;
}

.NodeRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NodeRowDisabled{
  opacity: 0.5;
}

.FilterContainer{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 10px;
  overflow-y: visible;
  height: 100%;
  min-height: 0;
}

.NodeRowExpand{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.NodeRowExpandPlaceholder{
  width: 22px;
  margin-right: 3px;
}

.NodeRowExpand:hover{
  color: #c2403c;
}

.NodeRowTop{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NodeRowTop:hover{
  color: #eeefed;
}

.NodeRowCheckbox{
  margin: 2px;
  height: 16px;
  width: 16px;
  border: 1px solid #969795;
  border-radius: 2px;
  position: relative;
}

.NodeRowTitle{
  margin-left: 5px;
  min-height: 100%;
  font-size: 90%;
}

.NodeRowCheckboxTicked{
  color: #c2403c;
  position: absolute;
  left: -1px;
  bottom: 7px;
  height: 100%;
}

.NodeRowCheckboxPartial{
  color: #c2403c;
  position: absolute;
  left: -3px;
  height: 100%;
  bottom: 12.5px;
}

.FilterRowContainer{
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  padding-left: 20px;
}

.FilterRow{
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
  align-items: center;
}

.FilterRowImage{
  height: 46px;
  width: 46px;
  margin-right: -8px;
  margin-bottom: -10px;
  margin-top: -6px;
}

.FilterRowTitle{
  margin-left: 5px;
  min-height: 100%;
  font-size: 80%;
}
.settingsRowCentered{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.QuitButton{
  font-size: 14px;
  color: #eeefed;
  border: 2px solid #c2403c;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.QuitButton:hover{
  color: #c2403c;
}

.ShareLocationRow{
  font-size: 14px;
  padding: 5px;
}

.ShareLocationInfoRow{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 5px;
  color: #a8a8a8;
  flex-direction: column;
}

.ShareLocationInput{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2px 20px;
  align-items: center;
}

.ShareLocationPlayer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 20px;
  align-items: center;
  cursor: pointer;
  color: #cfcfcf;
}

.ShareLocationPlayer:hover{
  color: #eeefed;
}

.ShareLocationPlayer.active{
  color: #eeefed;
}

.ShareLocationPlayerSpan{
  display: flex;
  align-items: center;
  justify-content: center;
}

.OpenMap{
  height: 124px;
  width: 133px;
  border: 1px solid #222;
  margin-top: 5px;
}

.JoinCode{
  margin-top: 5px;
  height: 211px;
  width: 397px;
  border: 1px solid #222;
}

.JoinCodeContainer{
  margin: 5px;
}

.LocationStatus{
  
  margin-top: -3px;
}

.LocationStatus{
  padding: 5px;
  height: 100%;
  display: inline-block;
  font-size: 12px;
  align-items: center;
  vertical-align: middle;
}

.EventStatusIndicator{
  margin-top: 5px;
  margin-left: 10px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  cursor: pointer;
}

.LocationStreamingInfo{
  font-size: 12px;
  color: #a8a8a8;
}

#info {
  position: absolute;
  z-index: 3000;
  background-color: #2e2d2c;
  border: 1px solid #222;
  color: #efefef;
  pointer-events: none;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.9;
}

.FAQheader {
  cursor: pointer;
  color: #c2403c;
}

.EventStatusIndicator{
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-left: 10px;
}

.EventStatus{
  padding: 3px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  height: 40px;
}

.ServiceStatusText{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.HelpTitle{
  font-size: 120%;
  color: #efefef;
}

a{
  color: #c2403c;
}

a:hover{
  color: #c2403c;
}

.PrivacyContent{
  text-align: left;
}
.ccpa{
  background-color: #393938;
  padding: 15px;
  border-radius: 8px;
}

th{
  background-color: #323231;
  padding: 5px;
}

table{
  width: 100%;
  padding: 5px;
  border: 1px solid #222;
  margin-bottom: 10px;
}

tr{
  padding: 5px;
  border-bottom: 1px solid #222;
}

td{
  padding: 5px;
}

#MetaTFTVideo{
  position: fixed;
  /* height: 300px;
  width: 400px; */
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 3000;
}